import React from 'react'

import './_index.scss'

function AboutPage () {
  return (
    <div className="page -about">
      <div>about page</div>
    </div>
  )
}

export default AboutPage
