import React from 'react'

import './_index.scss'

function CopyrightComponent () {
  const year = new Date().getFullYear()
  return (
    <div className="component -copyright">
      <span>STAR-tech, 2021-{year} @  Designed with coffee by </span>
      <a href="https://gkshi.github.io/" target="_blank" rel="noreferrer">George Shinkarev</a>
    </div>
  )
}

export default CopyrightComponent
