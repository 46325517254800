import React from 'react'

import MainSlider from '../../components/slider'
import Copyright from '../../components/copyright'

import './_index.scss'

function HomePage () {
  return (
    <div className="page -home flex j-center">
      <div className="container">
        <MainSlider />
      </div>

      <div className="copyright-container container copyright flex">
        <Copyright />
      </div>

      <div className="bg" />
      <div className="stars-bg" />
    </div>
  )
}

export default HomePage
