import React from 'react'
import { Link } from 'react-router5'

import IconLogo from '../icons/logo'
import UIButton from '../ui/button'

import './_index.scss'

function HeaderComponent () {
  return (
    <header className="component -header">
      <div className="container flex a-center">
        <div className="logo">
          <Link routeName="home">
            <IconLogo />
          </Link>
        </div>
        <nav>
          <a href="https://chromewebstore.google.com/detail/olbhbkoaachepoahjnpfcfbjmmhdcpbn" target="_blank" rel="noreferrer">VKADSKIP</a>
          <a href="https://passwords.star-tech.dev/" target="_blank" rel="noreferrer">Pass manager</a>
          <a href="https://todo.star-tech.dev/" target="_blank" rel="noreferrer">Todo manager</a>
          <a href="https://github.com/star-tech-dev" target="_blank" rel="noreferrer">GitHub</a>
        </nav>
        <div className="actions buttons" hidden>
          <UIButton theme="ghost">Log in</UIButton>
          <UIButton>Sign up</UIButton>
        </div>
      </div>
    </header>
  )
}

export default HeaderComponent
