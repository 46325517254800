import React from 'react'

import './_index.scss'

export interface SlideProps extends React.ComponentProps<any> {
  onInfoMouseEnter?: Function,
  onInfoMouseLeave?: Function
}

function SlideComponent ({ children, onInfoMouseEnter, onInfoMouseLeave }: SlideProps) {
  const onMouseEnter = (e: any) => {
    const isHoverToInfoBlock = !!e.target.closest('section.info')
    if (isHoverToInfoBlock) {
      onInfoMouseEnter && onInfoMouseEnter()
    } else {
      onInfoMouseLeave && onInfoMouseLeave()
    }
  }

  const onMouseLeave = (e: any) => {
    onInfoMouseLeave && onInfoMouseLeave()
  }

  return (
    <div className="component -slide flex a-center" onMouseMove={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </div>
  )
}

export default SlideComponent
