import React, { useEffect, useRef } from 'react'
import Slide, { SlideProps } from '../slide'

import UIButton from '../../ui/button'

function TodoSlideComponent (props: SlideProps) {
  const url = 'https://todo.star-tech.dev/'

  const videoRef = useRef(null)

  useEffect(() => {
    if (!videoRef.current) {
      return
    }
    // @ts-ignore
    videoRef.current.defaultMuted = true
    // @ts-ignore
    videoRef.current.muted = true
  }, [])

  return (
    <Slide onInfoMouseEnter={props.onInfoMouseEnter} onInfoMouseLeave={props.onInfoMouseLeave}>
      <section className="info">
        <h1>Welcome to STAR-tech.</h1>
        <h2 className="title">
          <div>Drag&apos;n&apos;drop</div>
          <div>task tracker</div>
        </h2>
        <div className="content">
          Use it as your personal todo manager. You can create boards, drag columns and&nbsp;drag cards between lists. <br/>
          Upload images into cards via clipboard or dragging. <br/>
          Customize labels by text and color and mark your cards.
        </div>
        <div className="actions buttons">
          <UIButton href={url} theme="white">Open tracker</UIButton>
        </div>
      </section>

      <section className="image flex center">
        <a href={url} target="_blank" rel="noreferrer">
          <video
            autoPlay={true}
            loop={true}
            controls={false}
            muted={true}
            poster="/slides/todo.png"
          >
            <source src="/slides/todo.mp4" />
          </video>
        </a>
      </section>
    </Slide>
  )
}

export default TodoSlideComponent
