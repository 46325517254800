import React, { useEffect, useRef } from 'react'

import Slide, { SlideProps } from '../slide'
import UIButton from '../../ui/button'

function PasswordsSlideComponent (props: SlideProps) {
  const url = 'https://passwords.star-tech.dev/'

  const videoRef = useRef(null)

  useEffect(() => {
    if (!videoRef.current) {
      return
    }
    // @ts-ignore
    videoRef.current.defaultMuted = true
    // @ts-ignore
    videoRef.current.muted = true
  }, [])

  return (
    <Slide onInfoMouseEnter={props.onInfoMouseEnter} onInfoMouseLeave={props.onInfoMouseLeave}>
      <section className="info">
        <h1>Welcome to STAR-tech.</h1>
        <h2 className="title">Personal secured password manager</h2>
        <div className="content">
          Use our secured personal password manager with <br/>
          256-bit AES&nbsp;encryption. It&apos;s free open source project. <br/>
          You can set advanced passphrase, lock the&nbsp;app <br/>
          without sign&nbsp;out and&nbsp;generate passwords there.
        </div>
        <div className="actions buttons">
          <UIButton href={url} theme="white">Open passwords</UIButton>
          <UIButton href="https://github.com/star-tech-dev/passwords-frontend" theme="hovered">GitHub</UIButton>
        </div>
      </section>

      <section className="image flex center">
        <a href={url} target="_blank" rel="noreferrer">
          <video
            ref={videoRef}
            autoPlay={true}
            loop={true}
            controls={false}
            muted={true}
            poster="/slides/passwords.png"
          >
            <source src="/slides/passwords.mp4" />
          </video>
        </a>
      </section>
    </Slide>
  )
}

export default PasswordsSlideComponent
