import React from 'react'

function ArrowIcon () {
  return (
    <svg width="7px" height="12px" viewBox="0 0 7 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1292.000000, -666.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(1227.000000, 641.000000)">
            <g transform="translate(47.000000, 10.000000)">
              <g transform="translate(18.750004, 15.749998)">
                <path d="M-1.87149154,2.42813721 C-2.24265097,2.79914648 -2.24265097,3.40093498 -1.87149154,3.77209441 L2.42829111,8.07192132 C2.60875844,8.25230097 2.85004038,8.35014901 3.10012676,8.35014901 C3.35021037,8.35014901 3.59144895,8.25242149 3.77188992,8.07212658 L8.07168094,3.77222493 C8.44298519,3.40110086 8.44298519,2.79924617 8.07159999,2.42819135 L7.98218346,2.34923792 C7.60947951,2.05942724 7.07051441,2.08577059 6.72791216,2.42823424 L3.09999641,6.05500206 L-0.52759559,2.42814235 C-0.898816582,2.05710155 -1.50045588,2.05710155 -1.87149154,2.42813721 Z" id="Path" transform="translate(3.100144, 5.250005) rotate(-90.000000) translate(-3.100144, -5.250005) "></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ArrowIcon
