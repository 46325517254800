import React, { useEffect, useRef } from 'react'

import Slide, { SlideProps } from '../slide'
import UIButton from '../../ui/button'

function VkadskipSlideComponent (props: SlideProps) {
  const url = 'https://chromewebstore.google.com/detail/olbhbkoaachepoahjnpfcfbjmmhdcpbn'

  return (
    <Slide onInfoMouseEnter={props.onInfoMouseEnter} onInfoMouseLeave={props.onInfoMouseLeave}>
      <section className="info">
        <h1>Welcome to STAR-tech.</h1>
        <h2 className="title">VKADSKIP - skips ads within VK Videos</h2>
        <div className="content">
          Tired of&nbsp;ads inside videos? Forget about endless
          interruptions in&nbsp;video clips&nbsp;&mdash; with this extension,<br/>
          you will watch videos without stops.
        </div>
        <div className="actions buttons">
          <UIButton href={url} theme="white">Download in Chrome Web Store</UIButton>
        </div>
      </section>

      <section className="image flex center">
        <a href={url} target="_blank" rel="noreferrer">
          <img src="/slides/vkadskip.png" alt=""/>
        </a>
      </section>
    </Slide>
  )
}

export default VkadskipSlideComponent
