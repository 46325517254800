import React, { useEffect, useRef, useState } from 'react'
import SwiperCore, { EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'

// import ProgressRing from '../progress-ring'
import VkadskipSlide from './slides/vkadskip'
import PasswordsSlide from './slides/passwords'
import TodoSlide from './slides/todo'
import IconArrow from '../icons/arrow'

import 'swiper/scss'
import 'swiper/scss/effect-fade'
import 'swiper/scss/autoplay'
import './_index.scss'

SwiperCore.use([EffectFade])

type Slider = SwiperClass

function SliderComponent () {
  const [swiper, setSwiper] = useState<Slider | null>(null)
  const [activeIndex, setActiveIndex] = useState(1)
  const [amount, setAmount] = useState(0)
  const parentRef = useRef(null)

  const nextSlide = () => {
    stopTimer()
    swiper?.activeIndex === amount - 1
      ? swiper?.slideTo(0)
      : swiper?.slideNext()
    startTimer()
  }

  const startTimer = () => {
    // swiper?.autoplay.start()
  }

  const stopTimer = () => {
    // swiper?.autoplay.stop()
  }

  const showSlider = () => {
    setTimeout(() => {
      // @ts-ignore
      (parentRef.current as HTMLElement).classList.remove('-hidden');
      // @ts-ignore
      (parentRef.current as HTMLElement).classList.add('-appearing')
    }, 300)

    setTimeout(() => {
      // @ts-ignore
      (parentRef.current as HTMLElement).classList.remove('-appearing')
    }, 1300)
  }

  const onSwiperSlide = (swiper: Slider) => {
    setActiveIndex(swiper.activeIndex + 1)
  }

  const onSwiperLoad = (swiper: Slider) => {
    setSwiper(swiper)
    setAmount(swiper.slides.length)
  }

  useEffect(() => {
    showSlider()
  }, [])

  return (
    <div ref={parentRef} className="component -slider -hidden">
      <Swiper
        // modules={[Autoplay]}
        effect="fade"
        slidesPerView={1}
        autoHeight={true}
        allowTouchMove={true}
        noSwipingClass="actions"
        centeredSlides={true}
        draggable={true}
        autoplay={false}
        // autoplay={{ delay: 7000 }}
        onSlideChange={onSwiperSlide}
        onSwiper={onSwiperLoad}
      >
        <SwiperSlide>
          <VkadskipSlide onInfoMouseEnter={stopTimer} onInfoMouseLeave={startTimer} />
        </SwiperSlide>
        <SwiperSlide>
          <PasswordsSlide onInfoMouseEnter={stopTimer} onInfoMouseLeave={startTimer} />
        </SwiperSlide>
        <SwiperSlide>
          <TodoSlide onInfoMouseEnter={stopTimer} onInfoMouseLeave={startTimer} />
        </SwiperSlide>
      </Swiper>

      {/* indicator */}
      <div className="indicator flex center">
        <div className="indexes">{activeIndex}/{amount}</div>
        <div className="next-button flex center" onClick={nextSlide}>
          <IconArrow />
          {/* <ProgressRing ref={progressBar} duration={delay} /> */}
        </div>
      </div>
    </div>
  )
}

export default SliderComponent
